import './productTourStep.css'

import { inject, observer } from 'mobx-react'
import React from 'react'
import trackUserEvent from 'src/components/trackEvents'
import { IRootStore } from 'src/store'
import { EventName, Events } from 'src/utils/gtm'

import { Paper } from '@material-ui/core'
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded'

import { IProductTourPositionStyle } from './ProductTourCopy'

interface IProductTourContent {
  title?: string
  body: string
}

interface IProductTourProps {
  store?: IRootStore
  content: IProductTourContent // content displayed in the dialog
  positionStyle?: IProductTourPositionStyle
  nextPage?: string // next page to be redirected to
  backDisabled?: boolean
  nextDisabled?: boolean
  finishTour?: boolean
  checkBeforeCCForm?: boolean
}

@inject('store')
@observer
export class ProductTourStep extends React.Component<IProductTourProps, {}> {
  constructor(props: IProductTourProps) {
    super(props)
  }
  public render() {
    const {
      content,
      finishTour,
      nextDisabled,
      nextPage,
      backDisabled,
      positionStyle,
    } = this.props
    const org = this.props.store?.organizations.current

    if (!org) {
      return
    }
    const { step } = org.onboarding
    const stepNumber =
      step < 4
        ? 1
        : step < 7
          ? 2
          : step < 9
            ? 3
            : step < 10
              ? 4
              : step < 11
                ? 5
                : 6
    return (
      <Paper className="product-tour-main" style={positionStyle} elevation={24}>
        <div className="product-tour-title">
          <div className="product-tour-step">Page {stepNumber} of 6</div>
          {content.title ? <div>{content.title}</div> : null}
        </div>
        <p className="product-tour-body">{content.body}</p>
        <footer className="product-tour-button-container">
          {backDisabled ? (
            <div />
          ) : (
            <button
              onClick={this.goToPreviousStep}
              className="product-tour-button-back"
            >
              <KeyboardArrowLeftRoundedIcon fontSize="small" />
              Back
            </button>
          )}

          <img />
          {nextDisabled ? (
            <div />
          ) : (
            <button
              onClick={nextPage ? this.goToNextPage : this.goToNextStep}
              className="product-tour-button-next"
            >
              {finishTour ? `Finish!` : `Next`}

              <KeyboardArrowRightRoundedIcon
                fontSize="small"
                className="product-tour-next-arrow"
              />
            </button>
          )}
        </footer>
      </Paper>
    )
  }

  private goToPreviousStep = async (e: any) => {
    const org = this.props.store?.organizations.current
    if (!org) return
    // prevents multiple clicks of button
    if (!e.detail || e.detail === 1) {
      await org.decProductTourStep()
    }
  }

  private goToNextStep = async (e: any) => {
    const { checkBeforeCCForm, finishTour } = this.props
    const org = this.props.store?.organizations.current
    if (!org) return
    if (finishTour) {
      await org.endProductTour()
      return
    }

    // prevents multiple clicks of button
    if (!e.detail || e.detail === 1) {
      // skip credit card capture step if already paying
      if (checkBeforeCCForm && org.plan.planType !== 'free') {
        await org.setProductTourStep(13)
        return
      }
      const dataLayer = {
        event: Events.onboarding.type,
        eventName: Events.onboarding.eventName,
        eventCode: Events.onboarding.eventCode,
      }
      trackUserEvent(EventName.PortalAction, dataLayer)
      if (
        !org.onboarding.tourCompleted &&
        org.onboarding.step === 11 &&
        org.plan.planType === 'free'
      ) {
        await org.setTrialRefused()
      }
      await org.incProductTourStep()
    }
  }

  private goToNextPage = async (e: any) => {
    const { nextPage } = this.props
    const history = this.props.store?.history
    const org = this.props.store?.organizations.current
    if (!org || !nextPage) {
      throw new Error(
        'Onboarding step missing organization or next redirect page',
      )
    }
    // prevents multiple clicks of button
    if (!e.detail || e.detail === 1) {
      await org.incProductTourStep()
      history?.push(nextPage)
      history?.goForward()
    }
  }
}
