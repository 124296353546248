export async function handleThemeCssChange({
  currentBot,
  property,
  newValue,
  changeContext,
  snackbarHandler,
}: any) {
  if (currentBot && currentBot.theme.css) {
    const css = currentBot.theme.css
    try {
      // If newValue is an object, it implies multiple CSS properties are being changed
      if (typeof newValue === 'object') {
        for (const [key, value] of Object.entries(newValue)) {
          await css.changeVal(key, value)
        }
      } else {
        await css.changeVal(property, newValue)
      }
      await currentBot.patchTheme()
      const successMessage = `Successfully changed the ${changeContext}.`
      snackbarHandler('success', successMessage)
    } catch (e) {
      console.error(`${changeContext} change error`, e)
      const errorMessage = `There was an error saving your change to the ${changeContext}. Please try again.`
      snackbarHandler('error', errorMessage)
    }
  }
}
