interface IProductTourCopyStep {
  content: {
    title?: string
    body: string
  }
  backDisabled?: boolean
  nextDisabled?: boolean
  positionStyle?: IProductTourPositionStyle
  finishTour?: boolean
  checkBeforeCCForm?: boolean
}

interface IProductTourCopy {
  dashboard: {
    create: IProductTourCopyStep
    select: IProductTourCopyStep
  }
  branding: {
    general: IProductTourCopyStep
    settings: IProductTourCopyStep
    components: IProductTourCopyStep
  }
  prompts: {
    general: IProductTourCopyStep
    preset: IProductTourCopyStep
  }
  engagements: {
    general: IProductTourCopyStep
  }
  account: {
    general: IProductTourCopyStep
  }
  connect: {
    general: IProductTourCopyStep
    ccCaptured: IProductTourCopyStep
    snippet: IProductTourCopyStep
    integrations: IProductTourCopyStep
  }
  complete: {
    general: IProductTourCopyStep
  }
}

export interface IProductTourPositionStyle {
  top?: number | string
  right?: number | string
  bottom?: number | string
  left?: number | string
  transform?: string
}

export const ProductTourCopy: IProductTourCopy = {
  dashboard: {
    // onboarding step 2
    create: {
      content: {
        title: `Let's get started!`,
        body: `Here is your Bot Dashboard.
        Click “Add a Bot” to create your first Bot. Select a platform (Dialogflow ES or Dialogflow CX) and give your Bot a name, label, and data region.`,
      },
      backDisabled: true,
      nextDisabled: true,
      positionStyle: {
        top: '20px',
        right: '20px',
      },
    },
    // onboarding step 3
    select: {
      content: {
        title: `Building your first Bot`,
        body: `Each Bot you create has a Branding and Bot Prompt section and connects to a Dialogflow agent of your choice. To explore Branding and Bot Prompts, please select the Bot you just created.`,
      },
      backDisabled: true,
      nextDisabled: true,
      positionStyle: {
        top: '10px',
        right: '10px',
      },
    },
  },
  branding: {
    // step 4
    general: {
      content: {
        title: `Branding your Bot`,
        body: `Customize colors, images, fonts and more in real-time.
        A live preview makes configuration easy. Pre-styled themes are also available in case you want to spin up something quick.`,
      },
      backDisabled: true,
      positionStyle: {
        top: 0,
        right: '20px',
      },
    },

    // step 5
    components: {
      content: {
        title: `Botcopy components`,
        body: `Your Branding Page also controls Botcopy components. (See the component list on the left of your screen.) These tools enable your Bot to ask for feedback or display a privacy policy consent form.`,
      },
      positionStyle: {
        top: 0,
        right: '20px',
      },
    },
    // step 6
    settings: {
      content: {
        title: `The Settings panel`,
        body: `Your Branding section is home to the Botcopy Settings panel, where you control window sizing, header and input bar style, greeter animations, and so much more. These details fine-tune the UX quickly – no coding needed.`,
      },
      positionStyle: {
        top: 0,
        right: '20px',
      },
    },
  },
  prompts: {
    // step 7
    general: {
      content: {
        title: `Bot Prompts`,
        body: `Mastering the use of Bot Prompts increases engagement! Using Bot Prompts, you can trigger specific Dialogflow intents depending on which page your user is on, e.g., pricing, support, specific product, etc. The user is then “prompted” with a relevant message from your Bot. An excellent way to personalize the user journey!`,
      },
      positionStyle: {
        top: '20px',
        right: '20px',
      },
      backDisabled: true,
    },
    // step 8
    preset: {
      content: {
        title: `Preset Prompts`,
        body: `IMPORTANT: A Preset Prompt doesn’t send a request to Dialogflow. Thus, it’s an outstanding option for your Welcome Message because you don’t incur any Google costs when your user initially interacts with the prompt. You can add the message and suggestions in your Bot Prompts section within Botcopy and tie the prompt to the URL of your choice. The Bot sends a Dialogflow request only when your end-user responds to the prompt. Great way to cut Dialogflow costs!`,
      },
      positionStyle: {
        top: '20px',
        right: '20px',
      },
    },
  },
  engagements: {
    // step 9
    general: {
      content: {
        title: `Tracking your engagements`,
        body: `Welcome to your Engagements page. You can check your engagement volume at a glance, view by engagements-per-day for a range of dates, or see your Bot’s conversion rate and total engagements across all your Bot. Expand the Filter section at the top to choose which bot data you want to appear on your Engagements page.  `,
      },
      backDisabled: true,
      positionStyle: {
        top: '20px',
        right: '20px',
      },
    },
  },
  account: {
    // step 10
    general: {
      content: {
        title: `Managing your organization`,
        body: `Your Account page is where you configure your organization info and settings. From here, you can invite coworkers or clients to collaborate on your projects. Invite unlimited team members and manage their roles; admin, developer, or marketer.`,
      },
      backDisabled: true,
      positionStyle: {
        top: '20px',
        right: '50px',
      },
    },
  },
  connect: {
    // step 11
    general: {
      content: {
        title: `Connecting bots to your NLU Platform (Dialogflow)`,
        body: `Hooray, you have a branded bot! But it won’t do anyone much good if it doesn’t have a brain—so it’s time to connect your bot to Dialogflow. 
        You can easily link your Botcopy bot to your Dialogflow agent with a single click on the Botcopy Connect Page.`,
      },
      backDisabled: true,
      positionStyle: {
        top: '20px',
        right: '20px',
      },
      checkBeforeCCForm: true,
    },
    // step 12 - credit card capture
    // step 13
    ccCaptured: {
      content: {
        title: `Linking a bot`,
        body: `Select your desired Dialogflow agent by following prompts on the page. 
        If you don’t have a Dialogflow agent tied to the email you used to sign up for Botcopy, 
        you’ll need to create a Dialogflow agent to continue the tour.`,
      },
      backDisabled: true,
      nextDisabled: true,
      positionStyle: {
        top: '20px',
        right: '20px',
      },
    },
    // step 14
    snippet: {
      content: {
        title: 'Adding a bot to your website',
        body: `Your Botcopy bot and your Dialogflow agent are now linked! 
        All that’s left is to copy the HTML snippet and paste it into your website’s HTML––it’s that easy. 😎
        Other options on this page let you adjust your bot’s name, your Dialogflow environment, and more.`,
      },
      backDisabled: true,
      positionStyle: {
        top: '45%',
        left: '70px',
      },
    },
    // step 15
    integrations: {
      content: {
        title: `Bot Integrations`,
        body: `Here are some valuable Botcopy integrations. Entirely optional but highly recommended! 
        If you want to add analytics or live chat handoff integration, this is where to do it.`,
      },
      positionStyle: {
        transform: 'translateY(-50px)',
      },
    },
  },
  // step 16
  complete: {
    general: {
      content: {
        title: `Fin!`,
        body: `That’s it! Thanks for completing the product tour. 
        You’re now part of the Botcopy Battalion which means we have your back! 
        See our Docs or click Support at the top of your page if you have questions. 
        Reach out to talk with our Support team anytime. Happy Botting!`,
      },
      positionStyle: {
        top: '20px',
        right: '20px',
      },
      finishTour: true,
    },
  },
}
