import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  FormLabel,
  Grid,
  HStack,
  IconButton,
  Image,
  Input,
  Select as ChakraSelect,
  SimpleGrid,
  Stack,
  Switch as ChakraSwitch,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import PaywallDialog from './Components/PaywallDialog'
import FocusTrapModal from './Components/FocusTrapModal'

import { whiteBotcopyIcons, blackBotcopyIcons } from './data/botcopyIcons'

import { uiByBotcopyThemes } from './data/uiByBotcopyThemes'

import fontFamily from './data/fontFamily'

import DefaultImage from './Components/DefaultImage'

import { handleDefaultStyle } from './utils/theme-styles'
import { playLiveChatEntrySound } from './utils/play-live-chat-entry-sound'
import { handleUiByBotcopyChange } from './utils/handle-ui-by-botcopy'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'

import CustomInput from './Components/CustomInput'

import {
  MdBrush as BrushRoundedIcon,
  MdCheckCircleOutline as CheckCircleIcon,
  MdClose as Close,
  MdErrorOutline as ErrorIcon,
  MdExpandMore as ExpandMoreIcon,
  MdInfoOutline as InfoIcon,
  // MdOutlineColorLens as ColorLens,
  MdPlayArrow as PlayCircleOutlineRoundedIcon,
} from 'react-icons/md'
import { tooltipText } from './data/tooltip-text'
import ChatWidget from './Components/ChatWidget'

import { isUndefined } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { ColorResult } from '../../../.types/react-color'
import {
  ISaveDetectorFormElements,
  noop,
  SAVE_DETECTOR_BAR_HEIGHT,
} from '../../components/saveDetector'
import { colors } from '../../hocs/withTheme'

import ImageSettings from './Components/ImageSettings'
import ColorSettings from './Components/ColorSettings'
import validateHex from './utils/validate-hex'

import { decimalToHex } from './utils/decimal-to-hex'

import ColorPickerModal from './Components/ColorPickerModal'
import { AppConfig } from '../../config'
import { IRootStore } from '../../store'
import { IBot } from '../../store/bots'
import { Events, EventName } from '../../utils/gtm'
import Logger from '../../utils/logger'
import CreditCardCapture from '../onboarding/CreditCardCapture'
import { ProductTourCopy } from '../onboarding/ProductTour/ProductTourCopy'
import { ProductTourStep } from '../onboarding/ProductTour/ProductTourStep'

import { parseUiByBotcopy } from './utils/parse-ui-by-botcopy'
import './branding.css'
import trackUserEvent from 'src/components/trackEvents'
import PreStyledThemesModal from './Components/PreStyledThemesModal'
import { styles } from './utils/styles'
import { handleThemeCssChange } from './utils/handle-theme-css-change'
import { handleNewPhoto } from './utils/handle-new-photo'

const { logError } = Logger('Branding')
const widgetBaseURL = AppConfig.get('/WidgetHostname')

const DimensionsWindowHeight = {
  Small: { label: 'Small', height: '560px', width: '405px' },
  Medium: { label: 'Medium', height: '635px', width: '461px' },
  Large: { label: 'Large', height: '695px', width: '505px' },
  XL: { label: 'XL', height: '742px', width: '535px' },
}

interface IBrandingProps extends RouteComponentProps<{ botId: string }> {
  classes: any
  store?: IRootStore
}

interface IBrandingState {
  newPhoto: object
  currentShape: string
  hexChange: boolean
  showFonts: boolean
  showDefaultImages: boolean
  showPreStyledThemes: boolean
  changed: boolean
  successSnackbar: boolean
  errorSnackbar: boolean
  displayPicker: boolean
  cssToBeChanged: string // store the css value the picker will manipulate
  imageToBeChanged: string
  enableFocusTrapDialog: boolean
  pickerColorChanged: boolean
  pickerLoading: boolean
  previewBot: boolean
  currentHex: string
  switchDialog: boolean
  snackbarMessage: string
}

@inject('store')
@observer
class Branding extends React.Component<IBrandingProps, IBrandingState> {
  private formElements: ISaveDetectorFormElements = {}
  private saveDetectorRef = React.createRef()

  constructor(props: IBrandingProps) {
    super(props)
    this.state = {
      currentShape: '',
      newPhoto: {},
      hexChange: false,
      showFonts: false,
      showDefaultImages: false,
      showPreStyledThemes: false,
      changed: false,
      successSnackbar: false,
      errorSnackbar: false,
      pickerColorChanged: false,
      cssToBeChanged: '',
      imageToBeChanged: '',
      currentHex: '',
      displayPicker: false,
      switchDialog: false,
      enableFocusTrapDialog: false,
      pickerLoading: false,
      previewBot: true,
      snackbarMessage: '',
    }

    this._renderConfiguration = this._renderConfiguration.bind(this)
    // this.handlePickerClick = this.handlePickerClick.bind(this)

    // logo shape
    // this.handleWidgetShape = this.handleWidgetShape.bind(this)
    // render inputs
    this.renderInput = this.renderInput.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  public componentWillUnmount() {
    this.props.store?.bots.setCurrentBotId(undefined)
  }

  public render() {
    const {
      match: {
        params: { botId },
      },
    } = this.props

    const currentBot = this.props.store?.bots.currentBot
    if (this.props.store?.bots.currentBotId !== botId) {
      this.props.store?.bots.setCurrentBotId(botId)
      return null
    }
    if (currentBot) {
      return this._renderConfiguration(currentBot)
    }
    return (
      <div style={{ padding: 50, color: colors.darkGreyBlue }}>Loading...</div>
    ) // TODO show a better loading screen
  }

  private _renderConfiguration(bot: IBot) {
    const store = this.props.store
    const org = this.props.store?.organizations.current
    const { css, images } = bot.theme
    const me = store?.users.me
    const currentBot = this.props.store?.bots.currentBot

    const onChangeUiByBotcopy = async (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const value = event.target.value
      await handleUiByBotcopyChange(
        currentBot as any,
        value,
        (status: string, message: string) => {
          this._handleSnackbar(status, message)
          console.log(`${status}: ${message}`)
        },
        (message: string, error: string) => {
          // eslint-disable-next-line no-restricted-globals
          this._handleSnackbar(status, message)
          console.error(message, error)
        },
      )
    }

    if (!store || !store.bots.currentBot || !org || !css || !images || !me) {
      return (
        <div style={{ padding: 50, color: colors.darkGreyBlue }}>
          Loading...
        </div>
      )
    }

    return (
      <Flex w="100%" direction="column">
        {org.notifications.overageFreeGrace >= 5 ? (
          <div className="onhold-banner">
            <Flex direction="column">
              <h1 className="onhold-banner-title">
                Your free trial with Botcopy is up!
              </h1>
              <h3 className="onhold-banner-subtitle">
                Upgrade now to one of our cost saving plans.
              </h3>
            </Flex>
            <a
              className="onhold-plan-button"
              href={`${process.env.PUBLIC_URL}/account?showPlans=true`}
            >
              Upgrade Plan
            </a>
          </div>
        ) : null}
        <Flex justify="flex-start" className="branding-top-bar" w="100%">
          {/* Pre-styled themes switch */}
          <Text
            className="show-themes-link"
            onClick={this._showPreStyledThemes}
          >
            <BrushRoundedIcon fontSize="small" className="brush-icon" />{' '}
            Pre-Styled Themes
          </Text>
        </Flex>
        {/* master branding container */}
        <Grid
          height="100%"
          alignItems="center"
          style={{
            paddingBottom: SAVE_DETECTOR_BAR_HEIGHT, // needed for save bar bottom
          }}
        >
          <CreditCardCapture classes={{}} store={store} />
          {this.state.previewBot ? (
            <ChatWidget botId={bot._id} widgetBaseURL={widgetBaseURL} />
          ) : null}

          <Flex
            direction="column"
            alignItems="center"
            w="100%"
            p={8}
            m="auto"
            maxW="1200px"
          >
            {org.onboarding.step === 4 ? (
              <ProductTourStep
                store={store}
                content={ProductTourCopy.branding.general.content}
                backDisabled={ProductTourCopy.branding.general.backDisabled}
                positionStyle={ProductTourCopy.branding.general.positionStyle}
              />
            ) : null}
            {org.onboarding.step === 5 ? (
              <ProductTourStep
                store={store}
                content={ProductTourCopy.branding.components.content}
                backDisabled={ProductTourCopy.branding.components.backDisabled}
                positionStyle={
                  ProductTourCopy.branding.components.positionStyle
                }
              />
            ) : null}
            {org.onboarding.step === 6 ? (
              <ProductTourStep
                store={store}
                content={ProductTourCopy.branding.settings.content}
                backDisabled={ProductTourCopy.branding.settings.backDisabled}
                positionStyle={ProductTourCopy.branding.settings.positionStyle}
                nextPage={`/bot/${org.onboarding.onboardingBotId}/bot-prompts`}
              />
            ) : null}
            {/* Preview Bot Switch */}
            <Flex mb={6} width="100%">
              <ChakraFormControl>
                <HStack>
                  <ChakraSwitch
                    isChecked={this.state.previewBot}
                    onChange={this._handlePreviewBot}
                  />
                  <ChakraFormLabel>Preview Bot</ChakraFormLabel>
                </HStack>
              </ChakraFormControl>
            </Flex>
            {/* Images row container */}
            <ImageSettings
              org={org}
              styles={styles}
              css={css}
              images={images}
              tooltipText={tooltipText}
              showDefaultImages={this._showDefaultImages}
              handleGreeterSize={(event: any) =>
                handleThemeCssChange({
                  currentBot: this.props.store?.bots.currentBot,
                  property: 'bcGreeterImageHeight',
                  newValue: event?.target?.value,
                  changeContext: 'greeter image size',
                  snackbarHandler: this._handleSnackbar,
                })
              }
              handleWidgetShape={(value: string) => {
                handleThemeCssChange({
                  currentBot,
                  property: 'bcWidgetShape',
                  newValue: value,
                  changeContext: 'greeter shape',
                  snackbarHandler: this._handleSnackbar,
                })
              }}
              handleNewPhoto={(event: any, photoType: any) =>
                handleNewPhoto(
                  event,
                  photoType,
                  this.props.store?.bots.currentBot,
                  this._handleSnackbar,
                )
              }
            />

            <ColorSettings
              org={org}
              styles={styles}
              css={css}
              tooltipText={tooltipText}
              handleDefaultGreeterStyleChange={(event: any) =>
                handleThemeCssChange({
                  currentBot,
                  property: 'defaultGreeterStyle',
                  newValue: event.target.value,
                  changeContext: 'Greeter\'s default style',
                  snackbarHandler: this._handleSnackbar,
                })
              }
              bot={bot}
              renderInput={this.renderInput}
              renderPicker={this.renderPicker}
            />

            {/* Advanced Settings*/}
            <Flex direction="column" w="100%">
              <Text textStyle="h5" fontSize="28px" my={6}>
                Advanced Settings
              </Text>
              <Accordion
                as={Card}
                bg={colors.lightGreyScale100}
                allowToggle={true}
                fullWidth={true}
                border="1px solid"
                borderColor={colors.lightGreyScale800}
              >
                <AccordionItem
                  style={
                    org.onboarding.step === 6
                      ? styles.rowHeader.expansionPanelSummaryActive
                      : {}
                  }
                >
                  <h2>
                    <AccordionButton
                      borderRadius={6}
                      border="none"
                      p={8}
                      h="auto"
                    >
                      <Box as="span" flex="1" textAlign="left">
                        <Text textStyle="h5" fontSize="21px">
                          Settings
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel p={8} pt={0}>
                    <Flex direction="column">
                      <Text textStyle="body2" mb={5}>
                        Greeter
                      </Text>
                      {/* GREETER ANIMATION  */}
                      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={6} my={5}>
                        {/* Greeter Load-in Animation GRID ITEM */}

                        {/* Select Greeter Animation */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">
                                Greeter Load-in Animation
                              </Text>
                              <Tooltip
                                label={tooltipText.bcGreeterAnimationName}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcGreeterAnimationName}
                            defaultValue={'centerExpand'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcGreeterAnimationName',
                                newValue: event.target.value,
                                changeContext: 'greeter load-in animation',
                                snackbarHandler: this._handleSnackbar,
                              })
                            }}
                            h="56px"
                          >
                            <option value={'centerExpand'}>
                              Center Expand
                            </option>
                            <option value={'expandOutIn'}>
                              Center Expand Out In
                            </option>
                            <option value={'kablam'}>Kablam</option>
                            <option value={'rightCornerExpand'}>
                              Right Corner Expand
                            </option>
                            {/* <option value={'shadowGlow'}>
                                      Shadow Glow
                                    </option> */}
                            {/* <option value={'spin'}>Spin</option> */}
                            <option value={'mold'}>Mold</option>
                            <option value={'openEye'}>Open Eye</option>
                            <option value={'sideEye'}>Side Eye</option>
                            <option value={'slide'}>Slide</option>
                            <option value={'none'}>None</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {/* Greeter Load-in Animation speed GRID ITEM */}

                        {/* Select Animation Speed */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mx={0}
                            mb={4}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text
                                textStyle="body2"
                                color={colors.lightGreyScale1100}
                              >
                                Greeter Load-in Speed
                              </Text>
                              <Tooltip
                                label={tooltipText.bcGreeterAnimationSpeed}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcGreeterAnimationSpeed}
                            defaultValue={'250ms'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcGreeterAnimationSpeed',
                                newValue: event.target.value,
                                changeContext: 'greeter load-in speed',
                                snackbarHandler: this._handleSnackbar,
                              })
                            }}
                            h="56px"
                          >
                            <option value={'900ms'}>Turtle</option>
                            <option value={'600ms'}>Super Slow</option>
                            <option value={'300ms'}>Slow</option>
                            <option value={'180ms'}>Medium</option>
                            <option value={'120ms'}>Fast</option>
                            <option value={'100ms'}>Very Fast</option>
                            <option value={'80ms'}>Rabbit</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {/* Greeter Load-in Animation Timing Function GRID ITEM */}

                        {/* Select Animation Speed */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">
                                Greeter Timing Function
                              </Text>
                              <Tooltip
                                label={
                                  tooltipText.bcGreeterAnimationTimingFunction
                                }
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcGreeterAnimationTimingFunction}
                            defaultValue={'ease-in'}
                            onChange={(event) => {
                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcGreeterAnimationTimingFunction',
                                newValue: event.target.value,
                                changeContext: 'greeter timing function',
                                snackbarHandler: this._handleSnackbar,
                              })
                            }}
                            h="56px"
                          >
                            <option value={'linear'}>Linear</option>
                            <option value={'ease'}>Ease</option>
                            <option value={'ease-in'}>Ease-in</option>
                            <option value={'ease-out'}>Ease-out</option>
                            <option value={'ease-in-out'}>Ease-in-out</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                      </SimpleGrid>
                    </Flex>
                    <div className="divider" />
                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Prompts
                      </Text>
                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={css.bcShowGreeter}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowGreeter',
                                    false,
                                    'Successfully toggled greeter and prompts',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                                alignItems="center"
                                textTransform="none"
                              >
                                Display Greeter and Prompts{''}
                                <Tooltip
                                  label={tooltipText.displayGreeter}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <div>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </div>
                                </Tooltip>
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={
                                  !css.bcShowGreeter ? false : css.bcShowPrompts
                                }
                                isDisabled={!css.bcShowGreeter}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowPrompts',
                                    false,
                                    'Successfully toggled prompts',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                fontSize="11px"
                              >
                                Display Prompts
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={css.bcShowPromptSuggestions}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowPromptSuggestions',
                                    false,
                                    'Successfully toggled prompt suggestions',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                fontSize="11px"
                              >
                                Display Suggestion Chips under Prompts
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Chat Window General Settings
                      </Text>

                      <SimpleGrid
                        columns={{ sm: 2, md: 3 }}
                        spacing={6}
                        my={5}
                        alignItems="end"
                      >
                        {/* Chat Window Type */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Text textStyle="body2">Window Style</Text>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcChatWindowType}
                            onChange={(e) => {
                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcChatWindowType',
                                newValue: e.target.value,
                                changeContext: 'chat window type',
                                snackbarHandler: this._handleSnackbar,
                              })
                            }}
                            h="56px"
                          >
                            <option value={'classic'}>Classic</option>
                            <option value={'contained'}>Contained</option>
                            <option value={'fullscreen'}>Full-screen</option>
                            <option value={'sidebar'}>Sidebar</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                        {/* Size  */}
                        {css.bcChatWindowType === 'cui' ? null : (
                          <ChakraFormControl>
                            <FormLabel
                              as={Text}
                              color={colors.lightGreyScale1100}
                              mb={4}
                              mx={0}
                              textTransform="none"
                            >
                              <Text textStyle="body2">Size</Text>
                            </FormLabel>
                            <ChakraSelect
                              value={css.bcDimensionsWindowHeight}
                              onChange={(event: any) => {
                                const value = event.target.value
                                // Renamed `currentBot` to `activeBot` to avoid potential shadowing issues
                                const activeBot =
                                  this.props.store?.bots.currentBot

                                const selectedDimension = Object.values(
                                  DimensionsWindowHeight,
                                ).find((dim) => dim.height === value)

                                if (selectedDimension) {
                                  handleThemeCssChange({
                                    currentBot: activeBot,
                                    property: 'bcDimensionsWindowHeight',
                                    newValue: selectedDimension.height,
                                    changeContext: 'window dimensions height',
                                    snackbarHandler: this._handleSnackbar,
                                  })
                                  handleThemeCssChange({
                                    currentBot: activeBot,
                                    property: 'bcDimensionsWindowWidth',
                                    newValue: selectedDimension.width,
                                    changeContext: 'window dimensions width',
                                    snackbarHandler: this._handleSnackbar,
                                  })
                                }
                              }}
                              h="56px"
                            >
                              <option
                                value={DimensionsWindowHeight.Small.height}
                              >
                                {DimensionsWindowHeight.Small.label}
                              </option>
                              <option
                                value={DimensionsWindowHeight.Medium.height}
                              >
                                {DimensionsWindowHeight.Medium.label}
                              </option>
                              <option
                                value={DimensionsWindowHeight.Large.height}
                              >
                                {DimensionsWindowHeight.Large.label}
                              </option>
                              <option value={DimensionsWindowHeight.XL.height}>
                                {DimensionsWindowHeight.XL.label}
                              </option>
                            </ChakraSelect>
                          </ChakraFormControl>
                        )}
                        <CustomInput
                          inputValue={String(css.bcZIndex)}
                          name={'bcZIndex'}
                          handleAsNumber={true}
                          label={'zIndex'}
                          type={'number'}
                          onChange={this.handleChange}
                          tooltipText={tooltipText.bcZIndex}
                        />
                        {/* Sidebar Options  */}
                        {css.bcChatWindowType === 'sidebar' ? (
                          <>
                            <ChakraFormControl>
                              <FormLabel
                                as={Text}
                                color={colors.lightGreyScale1100}
                                mb={4}
                                mx={0}
                                textTransform="none"
                              >
                                <Text textStyle="body2">Border Type</Text>
                              </FormLabel>
                              <RadioGroup
                                aria-label="window-height-radio"
                                value={css.bcSidebarBorderType}
                                onChange={(event, value) => {
                                  handleThemeCssChange({
                                    currentBot:
                                      this.props.store?.bots.currentBot,
                                    property: 'bcSidebarBorderType',
                                    newValue: value,
                                    changeContext: 'sidebar border type',
                                    snackbarHandler: this._handleSnackbar,
                                  })
                                }}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <FormControlLabel
                                  value="box-shadow"
                                  control={<Radio />}
                                  label="Box Shadow"
                                />
                                <FormControlLabel
                                  value="border-image"
                                  control={<Radio />}
                                  label="Border Image"
                                />
                              </RadioGroup>
                            </ChakraFormControl>
                            <>
                              <CustomInput
                                inputValue={css.bcSidebarMarginTop}
                                name={'bcSidebarMarginTop'}
                                handleAsNumber={true}
                                label={'Margin Top'}
                                type={'number'}
                                onChange={this.handleChange}
                                tooltipText={tooltipText.bcSidebarMarginTop}
                              />
                            </>
                            {css.bcSidebarBorderType === 'border-image' && (
                              // TODO: *Important* fix this before merging
                              <CustomInput
                                inputValue={
                                  css.bcSidebarBorderImageLinearGradient
                                }
                                name={'bcSidebarBorderImageLinearGradient'}
                                handleAsNumber={false}
                                label={'Linear Gradient'}
                                type={'string'}
                                onChange={this.handleChange}
                                tooltipText={
                                  tooltipText.bcSidebarBorderImageLinearGradient
                                }
                              />
                            )}
                          </>
                        ) : null}
                      </SimpleGrid>
                      <ChakraFormControl my={5}>
                        <HStack mr={6}>
                          <ChakraSwitch
                            isChecked={css.bcEnableFocusTrap}
                            onChange={this._toggleEnableFocusTrapDialog}
                          />
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            display="flex"
                          >
                            <Text textStyle="caption" fontSize="11px">
                              Focus Trap
                            </Text>
                          </FormLabel>
                        </HStack>
                      </ChakraFormControl>
                    </Flex>
                    <div className="divider" />

                    {/* Sounds  */}

                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Sounds
                      </Text>
                      <HStack
                        my={5}
                        spacing={4}
                        shouldWrapChildren={true}
                        alignItems="end"
                      >
                        {/* Live chat sounds*/}
                        <ChakraFormControl width="288px">
                          <FormLabel
                            as={Text}
                            textStyle="body2"
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">Live Chat Entry</Text>
                              <Tooltip
                                label={tooltipText.bcLiveChatEntrySound}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcLiveChatEntrySound}
                            onChange={(e) => {
                              const soundType: string = [
                                'sound1',
                                'sound2',
                              ].includes(e.target.value)
                                ? e.target.value
                                : 'sound1'

                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcLiveChatEntrySound',
                                newValue: e.target.value,
                                changeContext: 'live chat entry sound',
                                snackbarHandler: (
                                  status: string,
                                  message: string,
                                ) => {
                                  this._handleSnackbar(status, message)
                                  // Play the sound only if the change was successful
                                  if (status === 'success') {
                                    playLiveChatEntrySound(soundType)
                                  }
                                },
                              })
                            }}
                            h="56px"
                          >
                            <option value={'sound1'}>Sound 1</option>
                            <option value={'sound2'}>Sound 2</option>
                          </ChakraSelect>
                        </ChakraFormControl>
                        <IconButton
                          aria-label="play sound"
                          variant="light"
                          isRound={true}
                          size="sm"
                          h="32px"
                          bg={colors.lightGreyScale1200}
                          onClick={() =>
                            playLiveChatEntrySound(css.bcLiveChatEntrySound)
                          }
                          icon={
                            <PlayCircleOutlineRoundedIcon
                              color={colors.pureWhite}
                            />
                          }
                          mb="12px"
                        />
                      </HStack>
                    </Flex>
                    <div className="divider" />

                    {/* Header Grid  */}
                    <Flex direction="column" className="settings-container">
                      {/* Header Title  */}

                      <Text textStyle="body2" my={5}>
                        Header
                      </Text>

                      {/* HEADER TYPE GRID ITEM */}
                      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={6} my={5}>
                        {/* Select Header Type */}
                        <ChakraFormControl>
                          <FormLabel
                            as={Text}
                            color={colors.lightGreyScale1100}
                            mb={4}
                            mx={0}
                            textTransform="none"
                          >
                            <Flex justify="space-between">
                              <Text textStyle="body2">Type</Text>
                              <Tooltip
                                label={tooltipText.bcHeaderType}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>
                          </FormLabel>
                          <ChakraSelect
                            value={css.bcHeaderType}
                            onChange={(e) => {
                              handleThemeCssChange({
                                currentBot: this.props.store?.bots.currentBot,
                                property: 'bcHeaderType',
                                newValue: e.target.value,
                                changeContext: 'header type',
                                snackbarHandler: this._handleSnackbar,
                              })
                            }}
                            h="56px"
                          >
                            <option value={'classic'}>Classic</option>
                            {/* <option value={'modern'}>Modern</option> */}
                            <option value={'rich'}>Rich</option>
                          </ChakraSelect>
                        </ChakraFormControl>

                        {css.bcHeaderType === 'rich' ? (
                          <>
                            {/* Header Image Grid */}
                            <VStack>
                              <Flex
                                justify="space-between"
                                alignItems="center"
                                w="100%"
                                mb={4}
                                mx={0}
                              >
                                <Text
                                  textStyle="body2"
                                  color={colors.lightGreyScale1100}
                                >
                                  Header Image
                                </Text>
                                <Tooltip
                                  label={tooltipText.headerImage}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <span>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </span>
                                </Tooltip>
                              </Flex>
                              <Stack direction="column" w="100%">
                                <Flex
                                  justify="space-between"
                                  alignItems="flex-end"
                                >
                                  <Box
                                    backgroundColor={css.bcHeaderFillColor}
                                    className="header-image-background"
                                  >
                                    <Image
                                      src={images.headerImg}
                                      alt="header image"
                                      className="greeter-shapes"
                                      height={css.bcGreeterImageHeight}
                                      width={css.bcGreeterImageWidth}
                                      objectFit="contain"
                                    />
                                  </Box>

                                  {/* Header Upload Image */}

                                  <label htmlFor="contained-button-file-header-img">
                                    <Button
                                      as="span"
                                      cursor="pointer"
                                      size="sm"
                                      fontSize="sm"
                                      variant="light"
                                    >
                                      Choose File
                                    </Button>
                                  </label>
                                  <input
                                    accept="image/*"
                                    id="contained-button-file-header-img"
                                    multiple={false}
                                    type="file"
                                    hidden={true}
                                    onChange={(e) =>
                                      handleNewPhoto(
                                        e,
                                        'headerImg',
                                        this.props.store?.bots.currentBot,
                                        this._handleSnackbar,
                                      )
                                    }
                                  />
                                </Flex>
                                <Flex
                                  className="presetIconsButton"
                                  alignItems="center"
                                  onClick={() =>
                                    this._showDefaultImages('headerImg')
                                  }
                                >
                                  Icons
                                  <ExpandMoreIcon />
                                </Flex>
                              </Stack>
                            </VStack>
                            <CustomInput
                              inputValue={css.bcHeaderSubtitle}
                              name={'bcHeaderSubtitle'}
                              handleAsNumber={false}
                              label={'Subtitle'}
                              type={'string'}
                              onChange={this.handleChange}
                              tooltipText={tooltipText.headerSubtitle}
                            />
                          </>
                        ) : null}
                      </SimpleGrid>

                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={css.bcShowMaximizeButton}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowMaximizeButton',
                                    false,
                                    'Successfully toggled Maximize',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Maximize Icon
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={css.bcShowMoreOptionsMenu}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowMoreOptionsMenu',
                                    false,
                                    'Successfully toggled the Show More Options menu',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display More Options Menu
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={css.bcShowPrivacyPolicyMenuItem}
                                onChange={(e) =>
                                  this._handleBooleanChange(
                                    'bcShowPrivacyPolicyMenuItem',
                                    false,
                                    'Successfully toggled the Privacy Policy menu item',
                                  )
                                }
                                isDisabled={
                                  !css.bcShowMoreOptionsMenu ||
                                  !bot.theme.privacyPolicy.bcShowPrivacyPolicy
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Privacy Policy Menu Item
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack>
                              <ChakraSwitch
                                isChecked={css.bcShowClearConversationMenuItem}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowClearConversationMenuItem',
                                    false,
                                    'Successfully toggled the Clear Conversation menu item',
                                  )
                                }
                                isDisabled={!css.bcShowMoreOptionsMenu}
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Clear Conversation Menu Item
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    <Flex direction="column" my={5}>
                      {/* Body Title  */}

                      <Flex direction="column">
                        <Text textStyle="body2" my={5}>
                          Body
                        </Text>
                        <SimpleGrid
                          columns={{ sm: 2, md: 3 }}
                          my={5}
                          spacing={6}
                          alignItems="end"
                        >
                          {/* FONT TYPE GRID ITEM */}

                          {/* Select Font Type */}

                          <ChakraFormControl>
                            <FormLabel
                              as={Text}
                              color={colors.lightGreyScale1100}
                              mb={4}
                              mx={0}
                              textTransform="none"
                            >
                              <Flex justify="space-between">
                                <Text textStyle="body2">Font Type</Text>
                                <Tooltip
                                  label={tooltipText.fontFamily}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <div>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </div>
                                </Tooltip>
                              </Flex>
                            </FormLabel>
                            <ChakraSelect
                              height="56px"
                              onChange={(e) =>
                                handleThemeCssChange({
                                  currentBot,
                                  property: 'bcFontType',
                                  newValue: e.target.value,
                                  changeContext: 'font type',
                                  snackbarHandler: this._handleSnackbar,
                                })
                              }
                            >
                              {Object.entries(fontFamily).map(
                                ([category, fonts]) => (
                                  <optgroup label={category} key={category}>
                                    {fonts.map((font) => (
                                      <option value={font} key={font}>
                                        {font}
                                      </option>
                                    ))}
                                  </optgroup>
                                ),
                              )}
                            </ChakraSelect>
                          </ChakraFormControl>

                          <ChakraFormControl>
                            <FormLabel
                              as={Text}
                              color={colors.lightGreyScale1100}
                              mb={4}
                              mx={0}
                              textTransform="none"
                            >
                              <Flex justify="space-between">
                                <Text textStyle="body2">
                                  Text Alignment of Card Buttons
                                </Text>
                                <Tooltip
                                  label={tooltipText.cardButtonTextAlignment}
                                  placement={'top'}
                                  padding={5}
                                >
                                  <div>
                                    <InfoIcon
                                      color={colors.lightGreyScale1100}
                                      size="20px"
                                    />
                                  </div>
                                </Tooltip>
                              </Flex>
                            </FormLabel>
                            <ChakraSelect
                              value={css.bcCardButtonTextAlignment}
                              onChange={(event) => {
                                handleThemeCssChange({
                                  currentBot: this.props.store?.bots.currentBot,
                                  property: 'bcCardButtonTextAlignment',
                                  newValue: event.target.value,
                                  changeContext: 'card button text alignment',
                                  snackbarHandler: this._handleSnackbar,
                                })
                              }}
                              h="56px"
                            >
                              <option value={'left'}>Left</option>
                              <option value={'center'}>Center</option>
                              <option value={'right'}>Right</option>
                            </ChakraSelect>
                          </ChakraFormControl>

                          {/* Botcopy Logo Select */}
                          <VStack>
                            <Flex
                              justify="space-between"
                              alignItems="center"
                              w="100%"
                              mb={4}
                              mx={0}
                            >
                              <Text
                                textStyle="body2"
                                color={colors.lightGreyScale1100}
                              >
                                Logo Style
                              </Text>
                              <Tooltip
                                label={tooltipText.bcUiByBotcopy}
                                placement={'top'}
                                padding={5}
                              >
                                <div>
                                  <InfoIcon
                                    color={colors.lightGreyScale1100}
                                    size="20px"
                                  />
                                </div>
                              </Tooltip>
                            </Flex>

                            <FormControl fullWidth={true}>
                              <Select
                                disabled={!css.bcShowPoweredByBotcopy}
                                value={parseUiByBotcopy(currentBot?.theme.css)}
                                defaultValue={'Dark Blue'}
                                autoWidth={true}
                                onChange={onChangeUiByBotcopy}
                                MenuProps={{
                                  disableAutoFocusItem: true,
                                }}
                                SelectDisplayProps={{
                                  style: {
                                    display: 'flex',
                                    height: '35px',
                                  },
                                }}
                              >
                                {uiByBotcopyThemes.map((theme) => (
                                  <MenuItem
                                    value={theme.value}
                                    key={theme.value}
                                  >
                                    <img
                                      className="ui-by-img"
                                      src={`${process.env.PUBLIC_URL}${theme.imagePath}`}
                                      alt={theme.label}
                                    />
                                    {theme.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </VStack>
                        </SimpleGrid>
                      </Flex>
                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={css.bcShowAvatar}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowAvatar',
                                    false,
                                    'Successfully toggled bot avatar',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Bot Avatar
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>{' '}
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={css.bcShowPoweredByBotcopy}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowPoweredByBotcopy',
                                    true,
                                    'Successfully toggled UI by Botcopy',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Botcopy Logo
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                    <div className="divider" />

                    {/* Input Bar  */}
                    <Flex direction="column">
                      <Text textStyle="body2" my={5}>
                        Input Bar
                      </Text>

                      {/* Botcopy Input Bar Select */}

                      <ChakraFormControl my={5} width="288px">
                        <FormLabel
                          as={Text}
                          color={colors.lightGreyScale1100}
                          mb={4}
                          mx={0}
                          textTransform="none"
                        >
                          <Flex justify="space-between">
                            <Text textStyle="body2">Input Bar Style</Text>
                            <Tooltip
                              label={tooltipText.bcInputBarStyle}
                              placement={'top'}
                              padding={5}
                            >
                              <div>
                                <InfoIcon
                                  color={colors.lightGreyScale1100}
                                  size="20px"
                                />
                              </div>
                            </Tooltip>
                          </Flex>
                        </FormLabel>
                        <ChakraSelect
                          value={css.bcInputBarStyle}
                          defaultValue={'classic'}
                          onChange={(e) => {
                            handleThemeCssChange({
                              currentBot,
                              property: 'bcInputBarStyle',
                              newValue: e.target.value,
                              changeContext: 'input bar style',
                              snackbarHandler: this._handleSnackbar,
                            })
                          }}
                          h="56px"
                        >
                          <option value={'minimal'}>Minimal</option>
                          <option value={'classic'}>Classic</option>
                          {/* <option value={'Modern'}>Modern</option> */}
                          {/* <option value={'Future'}>Futuristic</option> */}
                        </ChakraSelect>
                      </ChakraFormControl>

                      <Wrap my={5} spacing={6}>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={css.bcShowMenu}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowMenu',
                                    false,
                                    'Successfully toggled the Menu button',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Menu
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                        <WrapItem>
                          <ChakraFormControl>
                            <HStack mr={6}>
                              <ChakraSwitch
                                isChecked={css.bcShowMicrophone}
                                onChange={() =>
                                  this._handleBooleanChange(
                                    'bcShowMicrophone',
                                    false,
                                    'Successfully toggled the Microphone button',
                                  )
                                }
                              />
                              <FormLabel
                                as={Text}
                                textStyle="caption"
                                color={colors.lightGreyScale1100}
                                display="flex"
                                fontSize="11px"
                              >
                                Display Microphone
                              </FormLabel>
                            </HStack>
                          </ChakraFormControl>
                        </WrapItem>
                      </Wrap>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
          {/* Greeter & Avatar Preset Icons */}
          {this.state.showDefaultImages && this.state.imageToBeChanged ? (
            <div className="presetIconsParent">
              <div className="presetIconsContainer">
                <Close
                  onClick={this.closeImages}
                  style={styles.settingsClose}
                />
                <Text className="settingsTitle">Botcopy Icons</Text>
                <Stack justify="center" alignItems="center" direction="row">
                  <Box
                    backgroundColor={colors.darkGreyBlue}
                    padding="5px"
                    marginTop="5px"
                  >
                    {whiteBotcopyIcons.map((image) => (
                      <DefaultImage
                        key={image.fileName}
                        fileName={image.fileName}
                        onImageSelected={this._imageSelected}
                      />
                    ))}
                  </Box>
                  <Box
                    backgroundColor={colors.offWhite}
                    padding="5px"
                    marginTop="5px"
                  >
                    {blackBotcopyIcons.map((image) => (
                      <DefaultImage
                        key={image.fileName}
                        fileName={image.fileName}
                        onImageSelected={this._imageSelected}
                      />
                    ))}
                  </Box>
                </Stack>
              </div>
            </div>
          ) : null}

          <ColorPickerModal
            isOpen={this.state.displayPicker}
            onClose={this._closePicker}
            currentHex={this.state.currentHex}
            onChangeComplete={this.pickerOnChangeComplete}
            onConfirm={this.handlePickerChange}
            loading={this.state.pickerLoading}
          />

          <PreStyledThemesModal
            isOpen={this.state.showPreStyledThemes}
            onClose={this._showPreStyledThemes}
            handleDefaultStyle={this._handleDefaultStyle}
            currentBot={this.props.store?.bots.currentBot}
          />

          <PaywallDialog
            isOpen={this.state.switchDialog}
            onClose={this._closeDialog}
            goToPlans={this._goToPlans}
            setUpMeeting={this._setUpMeeting}
          />

          <FocusTrapModal
            isOpen={this.state.enableFocusTrapDialog}
            onClose={this._closeDialog}
            confirmEnableFocusTrap={this._confirmEnableFocusTrap}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.successSnackbar}
            autoHideDuration={2500}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={styles.snackbar}
              message={
                <Flex style={styles.snackbarGrid} w="100%">
                  <CheckCircleIcon style={styles.snackbarIconCircle} />
                  {this.state.snackbarMessage}
                </Flex>
              }
            />
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={this.state.errorSnackbar}
            autoHideDuration={2500}
            onClose={this._closeSnackbar}
          >
            <SnackbarContent
              style={styles.errorSnackbar}
              message={
                <Flex style={styles.snackbarGrid} w="100%">
                  <ErrorIcon style={styles.snackbarIconCircle} />
                  {this.state.snackbarMessage}
                </Flex>
              }
            />
          </Snackbar>
        </Grid>
      </Flex>
    )
  }

  private renderInput = (
    inputValue: string,
    name: string,
    handleAsNumber: boolean = false,
    label?: string,
    type?: string,
  ) => {
    // dynamically add the form element for saveDetector
    const onKeyPress = async (
      e: React.KeyboardEvent<HTMLInputElement> &
        React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (e.key === 'Enter' && inputValue !== e.target.value.trim()) {
        return await this.handleChange(e, name, handleAsNumber, type)
      }
      return true
    }
    if (!this.formElements[name]) {
      this.formElements[name] = {
        ref: React.createRef(),
        inputRef: React.createRef(),
        onKeyPress,
        onChange: noop,
      }
    } else {
      // onKeyPress needs to be updated always, as the mobx store can change
      if (this.formElements[name].updateOnKeyPress) {
        this.formElements[name].updateOnKeyPress!(onKeyPress)
      }
      // need to change the default value,
      // in case user changing the color via color picker
      if (this.formElements[name].defaultValue !== inputValue) {
        this.formElements[name].defaultValue = inputValue
        this.formElements[name].onChange({ target: { value: inputValue } })
      } else if (
        this.saveDetectorRef.current &&
        (this.saveDetectorRef.current as any).wrappedInstance &&
        (this.saveDetectorRef.current as any).wrappedInstance.checkValuesChanged
      ) {
        // this is needed for selecting pre-styled themes
        const current = this.saveDetectorRef.current as any
        current.wrappedInstance.checkValuesChanged()
      }
    }
    const tooltip = tooltipText[name as keyof typeof tooltipText]

    return (
      <ChakraFormControl>
        <FormLabel
          as={Text}
          textStyle="body2"
          color={colors.lightGreyScale1100}
          mb={4}
          mx={0}
          textTransform="none"
        >
          <Flex justify="space-between">
            <Text textStyle="body2" color={colors.lightGreyScale1100}>
              {label}
            </Text>
            <Tooltip label={tooltip} placement={'top'} padding={5}>
              <div>
                <InfoIcon color={colors.lightGreyScale1100} size="20px" />
              </div>
            </Tooltip>
          </Flex>
        </FormLabel>
        <Input
          key={`${name}_${inputValue}`}
          placeholder={inputValue}
          defaultValue={inputValue}
          ref={this.formElements[name].inputRef}
          onKeyPress={this.formElements[name].onKeyPress}
          onChange={this.formElements[name].onChange}
          type={type}
          style={{ width: '100%' }}
          h="56px"
        />
      </ChakraFormControl>
    )
  }

  /**
   * @returns false if an error occurred
   */
  private handleChange = async (
    event: any,
    name: string,
    handleAsNumber: boolean = false,
    type?: string,
  ): Promise<boolean> => {
    const currentBot = this.props.store?.bots.currentBot
    let value = event.target.value.trim()

    // Check if we are explicitly handling a string type
    if (type === 'string') {
      // Directly assign the value without additional checks
    } else if (handleAsNumber) {
      value = String(value)
      if (isNaN(Number(value))) {
        this._handleSnackbar('error', 'Please enter a valid number.')
        return false
      }
    } else if (!validateHex(value)) {
      // For hex codes, ensure it starts with '#' and is a valid hex code
      value = value.startsWith('#') ? value : `#${value}`
      if (!validateHex(value)) {
        this._handleSnackbar('error', 'Please enter a valid hexcode.')
        return false
      }
    }

    if (currentBot && value !== '') {
      handleThemeCssChange({
        currentBot,
        property: name,
        newValue: value,
        changeContext: name,
        snackbarHandler: (status: string, message: string) => {
          this._handleSnackbar(status, message)
        },
      }).catch((e) => {
        console.error(`${name} Change Error`, e)
        this._handleSnackbar(
          'error',
          'There was an error saving your change. Please try again.',
        )
      })
    } else {
      this._handleSnackbar('error', 'Bot data is missing.')
      return false
    }
    return true
  }

  private _handleBooleanChange = async (
    cssVal: string,
    premiumFeature: boolean,
    snackbarMessage: string,
  ) => {
    const currentBot = this.props.store?.bots.currentBot
    const org = this.props.store?.organizations.current

    if (currentBot && currentBot.theme.css && org) {
      if (premiumFeature && org.plan.planType === 'free') {
        this.setState({ switchDialog: true })
      } else {
        handleThemeCssChange({
          currentBot,
          property: cssVal,
          // @ts-ignore
          newValue: !currentBot.theme.css[cssVal],
          changeContext: cssVal,
          snackbarHandler: this._handleSnackbar,
        })
      }
    }
  }

  private _closeDialog = () => {
    this.setState({ switchDialog: false, enableFocusTrapDialog: false })
  }

  private _handlePreviewBot = async () => {
    this.setState({ previewBot: !this.state.previewBot })
  }

  private _setUpMeeting = () => {
    const { store } = this.props
    const user = store?.users.me
    if (user) {
      this.setState({ switchDialog: false })
      const url = `https://calendly.com/botcopy?name=${user.name}&email=${user.email}`
      window.open(url, '_blank')
    }
  }

  private _handleSnackbar = async (type: string, snackbarMessage: string) => {
    await this.setState({ snackbarMessage })
    switch (type) {
      case 'success':
        this.setState({ successSnackbar: true })
        break

      case 'error':
        this.setState({ errorSnackbar: true })
        break
    }
  }

  private _closeSnackbar = () => {
    this.setState({
      successSnackbar: false,
      errorSnackbar: false,
    })
  }

  private _showPreStyledThemes = () => {
    if (!this.state.showPreStyledThemes) {
      this.setState({ showPreStyledThemes: true })
    } else {
      this.setState({ showPreStyledThemes: false })
    }
  }

  private _showDefaultImages = (imageToBeChanged: string) => {
    if (!this.state.showDefaultImages) {
      this.setState({ showDefaultImages: true, imageToBeChanged })
    } else {
      this.setState({ showDefaultImages: false, imageToBeChanged: '' })
    }
  }

  private closeImages = () => {
    this.setState({ showDefaultImages: false })
  }

  private _imageSelected = async (value: string) => {
    if (isUndefined(value)) return 'Image is undefined'
    const currentBot = this.props.store?.bots.currentBot
    const weblink = `${widgetBaseURL}/assets/images/defaultIcons/${value}`

    if (currentBot.theme.images) {
      try {
        await currentBot.theme.images.changeImage(
          this.state.imageToBeChanged,
          weblink,
        )
        await currentBot.patchTheme()
        this._handleSnackbar(
          'success',
          'Successfully changed your default image.',
        )
        return this.closeImages()
      } catch (e) {
        logError('Select Font Error', e)
        return this._handleSnackbar(
          'error',
          'There was an error saving your change. Please try again.',
        )
      }
    }
  }

  private pickerOnChangeComplete = (color: ColorResult) => {
    this.setState({
      pickerColorChanged: true,
      currentHex: `${color.hex}${decimalToHex(color.rgb.a)}`,
    })
  }

  private handlePickerChange = async () => {
    if (!this.state.pickerColorChanged) {
      this.setState({ displayPicker: false })
      return
    }
    const { cssToBeChanged, currentHex } = this.state
    const currentBot = this.props.store?.bots.currentBot

    handleThemeCssChange({
      currentBot,
      property: cssToBeChanged,
      newValue: currentHex,
      changeContext: 'HEX code',
      snackbarHandler: this._handleSnackbar,
    })

    this.setState({ displayPicker: false })
  }

  private renderPicker = (name: string, currentHex: string) => {
    this.setState({
      displayPicker: true,
      pickerColorChanged: false,
      cssToBeChanged: name,
      currentHex,
    })
  }

  private _closePicker = () => {
    this.setState({ displayPicker: false })
  }

  private _toggleEnableFocusTrapDialog = () => {
    const currentBot = this.props.store?.bots.currentBot

    if (currentBot && currentBot.theme.css.bcEnableFocusTrap) {
      this._handleBooleanChange(
        'bcEnableFocusTrap',
        false,
        'Successfully toggled Focus Trap',
      )
      this.setState({ enableFocusTrapDialog: false })
    } else {
      this.setState({ enableFocusTrapDialog: true })
    }
  }

  private _confirmEnableFocusTrap = () => {
    this._handleBooleanChange(
      'bcEnableFocusTrap',
      true,
      'Successfully toggled Focus Trap',
    )
    this.setState({ enableFocusTrapDialog: false })
  }

  private _handleDefaultStyle = async (currentBot: any, styleName: string) => {
    const result = await handleDefaultStyle(currentBot, styleName)

    this._handleSnackbar(result.status, result.message)
  }

  private _goToPlans = () => {
    const dataLayer = {
      event: Events.subscription.changePlan.viewPlans.type,
      eventName: Events.subscription.changePlan.viewPlans.eventName,
      eventCode: Events.subscription.changePlan.viewPlans.eventCode,
    }
    trackUserEvent(EventName.PortalView, dataLayer)
    window.open(`${process.env.PUBLIC_URL}/account?showPlans=true`, '_blank')
  }
}

// @ts-ignore
export default withRouter(Branding)
